import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContentfulApiService } from './contentful-api.service';
import { TypePageFields } from '../../../models/contentful-content-types/page';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private readonly getPagePath = '/api/contentful-api/page/';

  constructor(private contentfulApiService: ContentfulApiService) {}

  getPage(url: string, locale: string): Observable<TypePageFields> {
    return this.contentfulApiService
      .getPage({ slug: url, locale })
      .pipe(map((response) => response.items[0]?.fields || {}));
  }

  getPages(): Observable<any> {
    return this.contentfulApiService.getSitemapFromContentful()
  }
}
