import { ContentfulClientApi, createClient } from 'contentful';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AxiosAngularAdapterService } from './axios-angular-adapter.service';
import { ContentfulConfiguration } from '../../../models/contentful-configuration';
import { ContentfulContentTypes } from '../../../models/contentful-content-types.enum';
import { ContentfulPageQueryParams } from '../../../models/contentful-page-query-params';
import { TypePageFields } from '../../../contentful-content-types/page';

@Injectable({
  providedIn: 'root',
})
export class ContentfulApiService {
  readonly contentfulClient!: ContentfulClientApi<undefined>;

  private get contentfulConfiguration(): ContentfulConfiguration {
    return {
      spaceId: 'vjmccdv0h9py',
      accessToken: 'CqVD9Wt_pokhGC8cgYJdIwtJpWBA6Mvf5Oc4ytwhofQ',
      environment: 'master',

    };
  }

  constructor(private readonly axiosAdapter: AxiosAngularAdapterService) {
    this.contentfulClient = createClient({
      space: this.contentfulConfiguration.spaceId,
      accessToken: this.contentfulConfiguration.accessToken,
      environment: this.contentfulConfiguration.environment,
      adapter: this.axiosAdapter.adapter,

    });
  }

  private getFromContentful(
    type: ContentfulContentTypes,
    options?: { pageParams?: ContentfulPageQueryParams; limit?: number }
  ): Observable<any> {
    return from(
      this.contentfulClient.getEntries<TypePageFields>({
        content_type: type,
        locale: options?.pageParams?.locale,
        include: 10,
        'fields.slug': options?.pageParams?.slug,
      })
    );
  }

  getSitemapFromContentful(): Observable<any> {
    return from(
      this.contentfulClient.getEntries<TypePageFields>({
        content_type: 'page',
        locale: 'de-DE',
      })
    );
  }

  getPage(pageParams: ContentfulPageQueryParams): Observable<any> {
    return this.getFromContentful(ContentfulContentTypes.Page, { pageParams });
  }

  getAllPages(limit: number): Observable<any> {
    return this.getFromContentful(ContentfulContentTypes.Page, { limit });
  }
}
